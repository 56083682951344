.typewriter {
  display: flex;
  justify-content: flex-start;
  margin: 8px 0 0 48px;
  /* color: #5A5D7A; */
  font-family: 'Cascadia';
  font-weight: 700;
  /* font-family: monospace; */
  font-size: 48px;
  pointer-events: none;
}

.typewriter span {
  height: 48px;
  width: 28px;
  margin: 4px 8px 0;
  background-color: #000000ad;
  animation: blink 1s steps(1) infinite;
}

@keyframes blink {
  50% {
    background-color: transparent;
  }
}

@media only screen and (max-width: 1400px) {
  .typewriter {
    font-size: 36px;
    margin: 12px 0 0 0;
  }

  .typewriter span {
    height: 36px;
    width: 20px;
    margin: 2px 4px;
  }
}

@media only screen and (max-width: 900px) {
  .typewriter {
    font-size: 24px;
    margin: 12px 0 0 0;
  }

  .typewriter span {
    height: 28px;
    width: 16px;
    margin: 0 4px;
  }
}

@media only screen and (max-width: 500px) {
  .typewriter {
    font-size: 18px;
    margin: 0;
  }

  .typewriter span {
    height: 20px;
    width: 12px;
    margin: 1px 2px;
  }
}