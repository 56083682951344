body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat-Light", sans-serif;
  /* font-family: 'Monaco'; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F6F9FC;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h2 {
  font-family: 'Cascadia';
}

@font-face {
  font-family: "Montserrat";
  font-weight: normal;
  src: local("Montserrat"),
  url(/static/media/montserrat-regular.3fb4d290.woff) format("woff"),
  url(/static/media/montserrat-regular.b94d4bb6.woff2) format("woff2");
}

@font-face {
  font-family: "Montserrat";
  font-weight: bold;
  src: local("Montserrat-Bold"),
  url(/static/media/montserrat-bold.b760b19f.woff) format("woff"),
  url(/static/media/montserrat-bold.45af3727.woff2) format("woff2");
}

@font-face {
  font-family: "Montserrat-Light";
  src: local("Montserrat-Light"),
  url(/static/media/montserrat-light.a820606b.woff) format("woff"),
  url(/static/media/montserrat-light.d0d8af75.woff2) format("woff2");
}

@font-face {
  font-family: "Inconsolata";
  font-weight: normal;
  src: local("Inconsolata"),
  url(/static/media/Inconsolata-Regular.2c22fca2.ttf) format("ttf");
}

@font-face {
  font-family: "Inconsolata";
  font-weight: bold;
  src: local("Inconsolata-Bold"),
  url(/static/media/Inconsolata-Bold.9af662a0.ttf) format("ttf");
}

@font-face {
  font-family: "Monaco";
  font-weight: normal;
  src: local("Monaco"),
  url(/static/media/Monaco.5d922164.woff) format("woff");
}

@font-face {
  font-family: "Cascadia";
  font-weight: normal;
  src: local("Cascadia"),
  url(/static/media/CascadiaMono-SemiLight.fc23bc32.woff2) format("woff2");
}

@media only screen and (max-width: 900px) {
  body {
    display: block;
  }

  h2 {
    font-size: 18px;
    font-weight: bold;
  }

  h1 {
    font-size: 24px;
  }
}
.app-container {
  display: flex;
  flex-direction: column;
  margin: 0 80px;
}

@media only screen and (max-width: 900px) {
  .app-container {
    margin: 0 24px;
  }
}
.navbar {
  display: flex;
  width: 100%;
  margin: 40px 0;
  justify-content: flex-end;
}

.nav-item {
  margin-right: 32px;
  transition: -webkit-transform 0.1s;
  transition: transform 0.1s;
  transition: transform 0.1s, -webkit-transform 0.1s;
}

.nav-item:last-child {
  margin: 0;
}

.nav-item a {
  text-decoration: none;
  font-size: 16px;
  color: #000000;
  font-family: 'Cascadia';
  transition: opacity 0.1s;
}

.nav-item a:hover {
  opacity: 0.5;
}

.nav-item:hover {
  transform: scale(1.03);
  -webkit-transform: scale(1.03);
}

@media only screen and (max-width: 900px) {
  .navbar {
    justify-content: space-evenly;
  }

  .nav-item {
    margin: 0;
  }
}
.banner {
  display: flex;
  align-items: flex-start;
}

.portrait {
  margin: 0 0 25px;
  max-width: 180px;
  max-height: 180px;
  border-radius: 999px;
}

.image-links {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.typed-text {
  margin-top: 24px;
}

@media only screen and (max-width: 900px) {
  .banner {
    flex-direction: column;
    align-items: center;
  }

  .typed-text {
    width: 100%;
    margin-top: 36px;
  }
  
  .space-holder {
    height: 28px;
  }
}
.typewriter {
  display: flex;
  justify-content: flex-start;
  margin: 8px 0 0 48px;
  /* color: #5A5D7A; */
  font-family: 'Cascadia';
  font-weight: 700;
  /* font-family: monospace; */
  font-size: 48px;
  pointer-events: none;
}

.typewriter span {
  height: 48px;
  width: 28px;
  margin: 4px 8px 0;
  background-color: #000000ad;
  -webkit-animation: blink 1s steps(1) infinite;
          animation: blink 1s steps(1) infinite;
}

@-webkit-keyframes blink {
  50% {
    background-color: transparent;
  }
}

@keyframes blink {
  50% {
    background-color: transparent;
  }
}

@media only screen and (max-width: 1400px) {
  .typewriter {
    font-size: 36px;
    margin: 12px 0 0 0;
  }

  .typewriter span {
    height: 36px;
    width: 20px;
    margin: 2px 4px;
  }
}

@media only screen and (max-width: 900px) {
  .typewriter {
    font-size: 24px;
    margin: 12px 0 0 0;
  }

  .typewriter span {
    height: 28px;
    width: 16px;
    margin: 0 4px;
  }
}

@media only screen and (max-width: 500px) {
  .typewriter {
    font-size: 18px;
    margin: 0;
  }

  .typewriter span {
    height: 20px;
    width: 12px;
    margin: 1px 2px;
  }
}
.links {
  display: flex;
  flex-direction: row;
}

.link-item {
  display: flex;
  margin: 8px;
  padding-top: 3px;
  width: 44px;
  height: 44px;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
}

.link-item:hover {
  transition: -webkit-transform 0.1s;
  transition: transform 0.1s;
  transition: transform 0.1s, -webkit-transform 0.1s;
  transition: opacity 0.1s;
}

.link-item:hover {
  opacity: 0.5;
  transform: scale(1.03);
  -webkit-transform: scale(1.03);
}
/* .link-item:hover {
  background-color: #555;
  box-shadow: 0 0 10px 8px #555;
  transition: all 0.2s ease-in-out;
} */

.link-item img {
  width: 40px;
}
.bio {
  margin-top: 24px;
  line-height: 1.6em;
}

.bio h1 {
  margin: 20px 0 36px;
}

.bio p {
  font-size: 18px;
}

.bio a {
  color: red;
  text-decoration: none;
}

@media only screen and (max-width: 900px) {
  .bio p {
    font-size: 14px;
  }
}
.experience {
  display: flex;
  flex-direction: column;
  /* width: 1100px; */
  margin-top: 24px;
}
.experience-item {
  max-width: 1100px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 60px;
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.05),
      0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  /* transition: transform 0.2s ease-in; */
}

/* .experience-item:hover {
  transform: scale(1.01);
  -webkit-transform: scale(1.01);
} */

.experience-background {
  position: absolute;
  top: -1px;
  right: -1px;
  height: 110%;
  width: 80%;
  background: url(/static/media/vectorpaint.5188bdc9.svg);
  background-size: cover;
}

.experience-image {
  width: 40%;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  transition: all 0.07s ease-in;
}

.experience-image:hover {
  opacity: 0.85;
}


.experience-content {
  z-index: 2;
  min-height: 240px;
  width: 56%;
  padding: 48px;
  line-height: 1.6;
}

.experience-content ul {
  padding: 0;
  list-style-type: circle;
  list-style-position: outside;
}

.company {
  display: none;
}

.image-small {
  display: none;
  height: 48px;
}

.position {
  margin: 0;
}

.team {
  margin: 0;
  font-style: italic;
}

.date {
  font-style: italic;
  font-size: 0.9em;
}

.description {
  margin-bottom: 8px;
}

@media only screen and (max-width: 750px) {
  .experience-image, .experience-background {
    display: none;
  }

  .experience-content {
    min-height: 0;
  }

  .image-small {
    display: block;
    margin-bottom: 16px;
  }

  .position {
    font-size: 1em;
  }

  .team {
    font-size: 0.9em;
  }

  .experience-content {
    width: 100%;
    padding: 32px;
  }
}
.projects {
  /* max-width: 1100px; */
  /*text-align: right;*/
}
.project-item {
  max-width: 1100px;
  position: relative;
  display: flex;
  text-align: left;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 60px;
  border-radius: 4px;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.05),
      0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.project-background {
  position: absolute;
  top: -1px;
  left: -1px;
  height: 110%;
  width: 80%;
  background: url(/static/media/proj-background.de6f28cc.svg);
  background-size: cover;
}

.project-image {
  width: 24%;
  position: absolute;
  top: 48%;
  right: 24px;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  transition: all 0.1s ease-in;
}

.project-image:hover {
  opacity: 0.7;
}

.project-content {
  z-index: 2;
  min-height: 260px;
  width: 56%;
  padding: 48px;
  line-height: 1.6;
  overflow: auto;
}

.position {
  margin: 0;
}

.date {
  font-style: italic;
}

.project-subtitle {
  font-family: 'Cascadia';
  font-style: italic;
  color: #7d7d85;
}

.description {
  margin-bottom: 8px;
}

.tools {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 32px;
}

.links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 32px;
}

.url-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 18px 14px 0;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #ececec;
  box-shadow: 0 3px 7px rgba(50, 50, 93, 0.1),
              0 3px 6px rgba(0, 0, 0, 0.2);
  transition: -webkit-transform 0.1s;
  transition: transform 0.1s;
  transition: transform 0.1s, -webkit-transform 0.1s;
  transition: opacity 0.1s;
}

.url-link:hover {
  opacity: 0.5;
  transform: scale(1.03);
  -webkit-transform: scale(1.03);
}

.url-link img {
  margin-right: 8px;
  height: 20px;
}

.url-link:link {
  text-decoration: none;
}

.url-link p {
  margin: 0;
  font-family: 'Cascadia';
  color: #000000;
}

.tool {
  margin: 0 8px 4px 0;
  padding: 4px 8px;
  font-family: 'Cascadia';
  background-color: #7d7d85;
  border-radius: 4px;
  color: #ffffff;
  transition: opacity 0.1s;
}

.tool:hover {
  opacity: 0.5;
}

#asap-sports {
  background-color: #257964;
}

#smartender {
  background-color: #bcbcbc;
}

#issue-explorer {
  background-color: #E91E63;
}

#incognitoelf {
  background-color: #ffe75e;
}

#superrent {
  background-color: #343434;
}

#helpinghand {
  background-color: #78514e;
}

#metrics {
  background-color: #4391cc;
}

@media only screen and (max-width: 750px) {
  #asap-sports,
  #smartender,
  #issue-explorer,
  #superrent,
  #helpinghand,
  #metrics {
    background-color: #FFFFFF;
  }

  .project-image,
  .project-background {
    display: none;
  }

  .project-name {
    font-size: 1.5em;
  }

  .tools {
    font-size: 0.8em;
  }

  .project-content {
    width: 100%;
    padding: 32px;
  }
}
.skills-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

@media only screen and (max-width: 700px) {
  .skills-content {
    flex-direction: column;
  }
}
.skill-item {
  display: flex;
  flex-direction: column;
}

.skill-item:last-child {
  margin: 0;
}

.layer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.each-skill {
  display: flex;
  flex-direction: column;
}

.each-skill p {
  margin: 8px 0;
}

.layer {
  font-family: 'Cascadia';
}

.logo {
  width: 100px;
}

@media only screen and (max-width: 700px) {
  .skill-item {
    flex-direction: row;
    margin-bottom: 24px;
  }

  .each-skill {
    flex-direction: column;
    flex-wrap: wrap;
    height: 120px;
  }

  .each-skill p {
    margin: 8px 16px;
  }

  .layer {
    font-size: 1em;
  }
}
.footer {
  text-align: center;
  margin: 40px;
}

.footer div {
  padding: 8px;
}
