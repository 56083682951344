.experience-item {
  max-width: 1100px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 60px;
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.05),
      0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  /* transition: transform 0.2s ease-in; */
}

/* .experience-item:hover {
  transform: scale(1.01);
  -webkit-transform: scale(1.01);
} */

.experience-background {
  position: absolute;
  top: -1px;
  right: -1px;
  height: 110%;
  width: 80%;
  background: url('../assets/images/vectorpaint.svg');
  background-size: cover;
}

.experience-image {
  width: 40%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  transition: all 0.07s ease-in;
}

.experience-image:hover {
  opacity: 0.85;
}


.experience-content {
  z-index: 2;
  min-height: 240px;
  width: 56%;
  padding: 48px;
  line-height: 1.6;
}

.experience-content ul {
  padding: 0;
  list-style-type: circle;
  list-style-position: outside;
}

.company {
  display: none;
}

.image-small {
  display: none;
  height: 48px;
}

.position {
  margin: 0;
}

.team {
  margin: 0;
  font-style: italic;
}

.date {
  font-style: italic;
  font-size: 0.9em;
}

.description {
  margin-bottom: 8px;
}

@media only screen and (max-width: 750px) {
  .experience-image, .experience-background {
    display: none;
  }

  .experience-content {
    min-height: 0;
  }

  .image-small {
    display: block;
    margin-bottom: 16px;
  }

  .position {
    font-size: 1em;
  }

  .team {
    font-size: 0.9em;
  }

  .experience-content {
    width: 100%;
    padding: 32px;
  }
}