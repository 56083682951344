.banner {
  display: flex;
  align-items: flex-start;
}

.portrait {
  margin: 0 0 25px;
  max-width: 180px;
  max-height: 180px;
  border-radius: 999px;
}

.image-links {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.typed-text {
  margin-top: 24px;
}

@media only screen and (max-width: 900px) {
  .banner {
    flex-direction: column;
    align-items: center;
  }

  .typed-text {
    width: 100%;
    margin-top: 36px;
  }
  
  .space-holder {
    height: 28px;
  }
}