.navbar {
  display: flex;
  width: 100%;
  margin: 40px 0;
  justify-content: flex-end;
}

.nav-item {
  margin-right: 32px;
  transition: transform 0.1s;
}

.nav-item:last-child {
  margin: 0;
}

.nav-item a {
  text-decoration: none;
  font-size: 16px;
  color: #000000;
  font-family: 'Cascadia';
  transition: opacity 0.1s;
}

.nav-item a:hover {
  opacity: 0.5;
}

.nav-item:hover {
  transform: scale(1.03);
  -webkit-transform: scale(1.03);
}

@media only screen and (max-width: 900px) {
  .navbar {
    justify-content: space-evenly;
  }

  .nav-item {
    margin: 0;
  }
}