.app-container {
  display: flex;
  flex-direction: column;
  margin: 0 80px;
}

@media only screen and (max-width: 900px) {
  .app-container {
    margin: 0 24px;
  }
}