.skill-item {
  display: flex;
  flex-direction: column;
}

.skill-item:last-child {
  margin: 0;
}

.layer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.each-skill {
  display: flex;
  flex-direction: column;
}

.each-skill p {
  margin: 8px 0;
}

.layer {
  font-family: 'Cascadia';
}

.logo {
  width: 100px;
}

@media only screen and (max-width: 700px) {
  .skill-item {
    flex-direction: row;
    margin-bottom: 24px;
  }

  .each-skill {
    flex-direction: column;
    flex-wrap: wrap;
    height: 120px;
  }

  .each-skill p {
    margin: 8px 16px;
  }

  .layer {
    font-size: 1em;
  }
}