.bio {
  margin-top: 24px;
  line-height: 1.6em;
}

.bio h1 {
  margin: 20px 0 36px;
}

.bio p {
  font-size: 18px;
}

.bio a {
  color: red;
  text-decoration: none;
}

@media only screen and (max-width: 900px) {
  .bio p {
    font-size: 14px;
  }
}