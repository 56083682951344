.project-item {
  max-width: 1100px;
  position: relative;
  display: flex;
  text-align: left;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 60px;
  border-radius: 4px;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.05),
      0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.project-background {
  position: absolute;
  top: -1px;
  left: -1px;
  height: 110%;
  width: 80%;
  background: url('../assets/images/proj-background.svg');
  background-size: cover;
}

.project-image {
  width: 24%;
  position: absolute;
  top: 48%;
  right: 24px;
  transform: translate(0, -50%);
  transition: all 0.1s ease-in;
}

.project-image:hover {
  opacity: 0.7;
}

.project-content {
  z-index: 2;
  min-height: 260px;
  width: 56%;
  padding: 48px;
  line-height: 1.6;
  overflow: auto;
}

.position {
  margin: 0;
}

.date {
  font-style: italic;
}

.project-subtitle {
  font-family: 'Cascadia';
  font-style: italic;
  color: #7d7d85;
}

.description {
  margin-bottom: 8px;
}

.tools {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 32px;
}

.links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 32px;
}

.url-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 18px 14px 0;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #ececec;
  box-shadow: 0 3px 7px rgba(50, 50, 93, 0.1),
              0 3px 6px rgba(0, 0, 0, 0.2);
  transition: transform 0.1s;
  transition: opacity 0.1s;
}

.url-link:hover {
  opacity: 0.5;
  transform: scale(1.03);
  -webkit-transform: scale(1.03);
}

.url-link img {
  margin-right: 8px;
  height: 20px;
}

.url-link:link {
  text-decoration: none;
}

.url-link p {
  margin: 0;
  font-family: 'Cascadia';
  color: #000000;
}

.tool {
  margin: 0 8px 4px 0;
  padding: 4px 8px;
  font-family: 'Cascadia';
  background-color: #7d7d85;
  border-radius: 4px;
  color: #ffffff;
  transition: opacity 0.1s;
}

.tool:hover {
  opacity: 0.5;
}

#asap-sports {
  background-color: #257964;
}

#smartender {
  background-color: #bcbcbc;
}

#issue-explorer {
  background-color: #E91E63;
}

#incognitoelf {
  background-color: #ffe75e;
}

#superrent {
  background-color: #343434;
}

#helpinghand {
  background-color: #78514e;
}

#metrics {
  background-color: #4391cc;
}

@media only screen and (max-width: 750px) {
  #asap-sports,
  #smartender,
  #issue-explorer,
  #superrent,
  #helpinghand,
  #metrics {
    background-color: #FFFFFF;
  }

  .project-image,
  .project-background {
    display: none;
  }

  .project-name {
    font-size: 1.5em;
  }

  .tools {
    font-size: 0.8em;
  }

  .project-content {
    width: 100%;
    padding: 32px;
  }
}