.skills-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

@media only screen and (max-width: 700px) {
  .skills-content {
    flex-direction: column;
  }
}