.links {
  display: flex;
  flex-direction: row;
}

.link-item {
  display: flex;
  margin: 8px;
  padding-top: 3px;
  width: 44px;
  height: 44px;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
}

.link-item:hover {
  transition: transform 0.1s;
  transition: opacity 0.1s;
}

.link-item:hover {
  opacity: 0.5;
  transform: scale(1.03);
  -webkit-transform: scale(1.03);
}
/* .link-item:hover {
  background-color: #555;
  box-shadow: 0 0 10px 8px #555;
  transition: all 0.2s ease-in-out;
} */

.link-item img {
  width: 40px;
}